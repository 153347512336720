ul {
  list-style: none;
}
:root {
  --primary-color: rgba(24, 128, 56, 1);
}
.text-link {
  color: rgba(24, 128, 56, 1);
  cursor: pointer;
}
* {
  font-family: "Sora", sans-serif;
}
a {
  text-decoration: none !important;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}
 
.left-content {
  border-radius: 0;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 70px;
  padding-top: 45px;
}
.logo {
  margin-bottom: 0;
  margin-right: 20px;
}
.logo img {
  width: 40px;
}

.input-group-prepend .input-group-text {
  height: 100%;
  background-color: transparent;
  border-right: 0;
  border-color: #bebebe;
  border-radius: 5px 0 0 5px;
}

.input-group-text .start-dot {
  width: 15px;
  height: 15px;
  border: 4px solid var(--primary-color);
  background: #fff;
  border-radius: 50%;
}
.input-group-text .via-dot {
  width: 15px;
  height: 15px;
  border: 4px solid #9d9d9d;
  background: #fff;
  border-radius: 50%;
}
.input-group-text .end-dot {
  width: 15px;
  height: 15px;
  border: 4px solid #ff5b5b;
  background: #fff;
  border-radius: 50%;
}
.inputstyle {
  height: 50px;
  background: transparent;
  border-left: 0;
  color: #2e2e2e;
  padding-left: 0;
  border-right: none !important;
  border-left: none !important;
}
.inputstyle:focus {
  box-shadow: none !important;
  border-top: 1px solid #ced4da !important;
  border-bottom: 1px solid #ced4da !important;
}
.input-group-append .input-group-text a {
  color: #000;
  padding-top: 5px;
}

.text-2 {
  background-color: transparent !important;
  border-radius: 0 5px 5px 0 !important;
  height: 100%;
}
.home-icon a {
  text-decoration: none;
  margin: 20px 0;
}
.home-icon a i {
  padding: 0 9px;
  color: rgba(24, 128, 56, 1);
  font-size: 18px;
}
.home-icon a span {
  align-items: center;
  color: #5a5a5a;
  font-size: 17px;
  font-weight: 350;
}
.btn-main {
  color: #ffffff;
  font-weight: 800;
  background: rgba(24, 128, 56, 1);
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 0px;
  letter-spacing: 0.2px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  border: 0;
}
.btn-main:hover {
  color: #fff !important;
}
.fix-btn-bg {
  display: block;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  background: #fff;
}
.page-wrapper .left-content {
  padding-top: 20px;
}
.page-wrapper > div {
  width: auto !important;
  height: 70vh !important;
}
/* Vehicle Selection */
.back-btn {
  color: #5928ca !important;
  margin-bottom: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.back-btn span {
  margin-left: 5px;
  font-size: 15px;
  color: rgba(24, 128, 56, 1);
}
.back-btn i {
  font-size: 20px;
  color: #8f8f8f;
}
.vehicle-img img {
  width: 90px;
}
/* .vehicle-select::-webkit-scrollbar {
  display: none;
} */

.extrawrapper::-webkit-scrollbar {
  width: 10px;
}

.extrawrapper::-webkit-scrollbar-track {
  background: #ebebeb;
}

.extrawrapper::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
}

.extrawrapper::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}
.rainbow-m-vertical_x-large {
  width: 100% !important;
}
.react-datepicker__input-container input {
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 0;
  height: 50px;
  font-size: 14px;
  border: 2px solid #6a6a6a;
}
#input-4 {
  padding-right: 10px !important;
}
.vehicle-select {
  max-height: 62vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.extrawrapper .capacity {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.extrawrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}
.vehicle-desc {
  text-align: left;
}
.vehicle-desc span {
  margin-right: 10px;
}
.vehicle-desc i {
  color: #5d5d5d;
  font-size: 13px;
  padding-right: 3px;
}
.vehicle-desc p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px !important;
}
.vehicle-select-align {
  padding: 10px;
  border: 1px solid #fff;
  border-bottom: 1px solid #ebebeb;
}
.vehicle-select-align{
  gap: 10px;
}
.vehicle-select-align.active {
  background: #ededed;
  border: 1px solid #c5c5c5;
}
.vehicle-fare span {
  float: right;
}
.vehicle-fare span i {
  color: #6e6e6e;
  padding-left: 15px;
}
.vehicle-fare p {
  color: rgba(24, 128, 56, 1);
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.back-btn-div.mb-4 {
  margin: 0 !important;
  padding: 0 30px;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 32%);
}

.back-btn-div p {
  margin-left: 40px !important;
  color: rgba(24, 128, 56, 1);
  font-weight: 500;
  font-size: 18px;
}

/* Calender */
.Calendar {
  width: 30em !important;
  --cl-color-black: #444444;
  --cl-color-disabled: #d4d4d4;
  --cl-color-error: #444444 !important;
}
.fix-btn-bg div p {
  color: var(--primary-color);
  font-size: 18px;
}

/* Add Extras */
.vehicle-bg {
  padding: 0 0 20px 0;
  background-color: #f5f3f3;
}
.vehicle-bg figure {
  text-align: center;
  margin: 0 !important;
}
.vehicle-bg figure img {
  width: 160px;
}
.vehicle-bg-desc {
  text-align: center;
}
.vehicle-bg-desc span:first-child {
  margin-right: 10px;
  margin-bottom: 10px;
}
.journey-desc-title p {
  color: #767272;
  padding-left: 20px;
  text-transform: uppercase;
}
.journey-desc-title {
  margin-left: 20px;
}
.journey-info {
  padding: 25px;
  border-radius: 0px;
  min-height: 70vh;
  background: #e9e9e9;
}
.journey-info h1 {
  font-size: 22px;
  font-weight: 900;
  color: var(--primary-color);
  margin-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.journey-info h1 span {
  color: #000;
}
.journey-info-content:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
}
.journey-info-content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #e9dcdc;
  padding-bottom: 20px;
}
.journey-info-content div {
  margin-left: 20px;
}
.journey-info-content div h4 {
  font-size: 14px;
  margin-bottom: 8px !important;
}
.journey-info-content div p {
  font-size: 13px;
}
.vehicle-extra-info-page .left-content {
  padding-bottom: 110px;
}

.left-content::-webkit-scrollbar {
  width: 5px;
}

.left-content::-webkit-scrollbar-track {
  background-color: #cdcdcd;
}

.left-content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgb(31, 31, 31);
}
.return-card {
  background-color: #ebf3ef;
  padding: 20px;
  margin-top: 20px;
  border-radius: 0px;
}
.calender-page .left-content {
  padding-bottom: 140px;
}
.return-booking h4 {
  font-size: 15px;
  color: #625a5a;
}
.capacity {
  margin-bottom: 20px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 0px;
  box-shadow: 0 0 5px 0px #959595;
}
.capacity h3 {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #1c1c1c;
}
input::placeholder {
  color: rgb(27, 27, 27) !important;
  opacity: 1;
  text-transform: capitalize !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(27, 27, 27);
  opacity: 1;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(27, 27, 27);
  opacity: 1;
}
select:focus,
input:focus {
  box-shadow: none !important;
  border-color: #c3c3c3 !important;
}
.capacity p {
  font-size: 12px;
  color: #bbafaf;
  margin-bottom: 10px !important;
}
select {
  border: none !important;
  width: 20% !important;
  padding: 0 !important;
}
.select-align-div {
  background: #fff;
  padding: 0 5px;
  height: 50px;
}
.note-to-driver h3 {
  cursor: pointer;
}
.modal-dialog {
  max-width: 400px !important;
}
.modal-title {
  font-size: 18px !important;
}
.capacity.modal-body {
  background: #fff;
  margin-bottom: 0;
}
.note-to-driver h3 span {
  margin-left: 10px;
}
.modal-header,
.modal-footer {
  border: none !important;
}
.custom-modal-title {
  color: var(--primary-color) !important;
}
.modal-header .btn-close {
  color: var(--primary-color) !important;
}
.modal-header .btn-close:hover {
  color: var(--primary-color) !important;
}
.modal-header .btn-close:focus {
  box-shadow: none !important;
  border: none !important;
}
.modal-body {
  padding-top: 0 !important;
}
.custom-modal-title {
  margin-bottom: 15px;
  text-align: center;
}
.booking-btn-conform {
  background: green;
  text-align: center;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px !important;
  display: inline-block;
}
.booking-btn-conform:hover {
  color: #fff;
}
.driver-on-destination-distance {
  color: var(--primary-color);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.vehicle-select a {
  color: #000;
}
.vehicle-select a:hover {
  color: #000;
}
.capacity h3.mb-0 {
  margin-bottom: 0 !important;
}

header .nav a {
  color: #4e4e4e;
  font-weight: 700;
}
header {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  border-bottom: 2px solid #ddd;
}
header .nav li {
  padding: 0 20px;
}
header .notification-link,
header .notification-link:hover {
  position: absolute;
  right: 40px;
  background-color: #229556;
  width: 40px;
  height: 40px;
  padding: 8px 13px;
  border-radius: 50%;
  color: #fff;
}
.pac-target-input {
  width: 370px !important;
}
.floating-sidebar {
  position: relative;
  width: 100%;
  background: #fff;
  min-height: 70vh;
  border-radius: 0px;
}

.mapiframe {
  height: 100vh;
  width: 100%;
  border: 0;
}

.floating-sidebar h1 {
  font-size: 22px;
  margin-bottom: 20px !important;
  font-weight: 900;
}
.extrawrapper .input-group input,
.extrawrapper .input-group .input-group-text {
  border: 0 !important;
}
.extrawrapper textarea,
.extrawrapper .input-group {
  border: 2px solid #6a6a6a;
  border-radius: 0 !important;
}
.sidebar-booking .input-group:last-child {
  display: none;
}
.sidebar-booking .input-group {
  border: 2px solid #6a6a6a;
}
.sidebar-booking .input-group:last-child {
  border-bottom: 0;
}
.sidebar-booking .input-group:last-child .input-group-append {
  display: none;
}
.sidebar-booking input,
.sidebar-booking .input-group-text {
  border: 0;
}

.sidebar-booking .field-wrapper {
  display: block !important;
  width: 100% !important;
}
.sidebar-booking .input-group-append {
  position: absolute;
  top: 34px;
  right: 0;
  width: 50px;
  height: 30px;
  background: #fff;
  display: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}
.sidebar-booking .form-control {
  outline: 0;
  border: 0 !important;
  background: none;
}
.sidebar-booking .form-control:focus {
  outline: 0;
  border: 0 !important;
  background: none;
}
.from-to {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  background: #fff;
  padding: 3px;
  border-radius: 0px;
}
.from,
.to {
  width: 50%;
  height: 55px;
  position: relative;
}

.from-to label,
.from-to input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 700;
}

.from-to input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.from-to input[type="radio"] + label {
  text-transform: uppercase;
  line-height: 45px;
  background: #cbedda;
  border-radius: 0;
}
.from-to input[type="radio"]:checked + label {
  background: #229556;
  border-radius: 0px;
  color: #fff;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1 !important;
}
.from-to label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
}

.floating-sidebar .Calendar {
  box-shadow: none !important;
  max-width: 100%;
  width: 100% !important;
  padding: 0;
  min-height: auto;
}
#time-picker_time-input span svg {
  display: none;
}
#time-picker_time-input input {
  border: 0 !important;
}

.floating-sidebar .Calendar__header {
  padding: 10px;
}
.floating-sidebar .Calendar__section {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.floating-sidebar .Calendar__weekDays {
  padding: 0 21px;
}

.chkbx-toggle {
  display: none;
}
.chkbx-toggle + label {
  position: relative;
  cursor: pointer;
  outline: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 40px;
  height: 20px;
  background-color: #dddedf;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.chkbx-toggle + label::before,
.chkbx-toggle + label::after {
  content: "";
  display: block;
  position: absolute;
}
.chkbx-toggle + label::before {
  right: 1px;
  left: 1px;
  top: 1px;
  bottom: 1px;
  background-color: #919191;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.chkbx-toggle + label::after {
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  -moz-transition: -moz-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}
.chkbx-toggle:checked + label {
  background-color: #188038;
}
.chkbx-toggle:checked + label::before {
  background-color: #188038;
}
.chkbx-toggle:checked + label::after {
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
.chkbx-toggle:disabled + label {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  cursor: default;
}
.w-82 {
  width: 80%;
  margin-left: 10px !important;
}
.select-align-div .input-group-prepend .input-group-text {
  border: 0;
}
.select-align-div .form-select {
  display: block;
  width: 100% !important;
  line-height: 48px;
  background: transparent;
}
.select-align-div label {
  font-size: 13px;
}
.select-align-div {
  border: 2px solid #c5c5c5;
  flex-wrap: nowrap !important;
  margin-bottom: 10px;
}
h3.justify-content-between {
  font-size: 16px;
  margin-top: 20px !important;
}
input[type="text"] {
  height: 50px;
  font-size: 14px;
}
[name="payment"] {
  margin-right: 10px;
}
.extrawrapper p.sm {
  font-size: 13px;
  color: rgb(104, 104, 104) !important;
  margin-bottom: 10px !important;
}

.extrawrapper .input-group-prepend .input-group-text,
.modal-dialog .input-group-prepend .input-group-text {
  background: #fff;
  border-right: 0;
}
.extrawrapper .input-group input,
.modal-dialog .input-group input {
  border-left: 0;
}

.booking-summery {
  /* position: absolute; */
  /* right: 20px;
  top: 100px;
  width: 400px; */
  background: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 4px 2px #ddd; */
}
.fleet-img {
  width: 100px;
}

.card-expiry-date fieldset {
  top: 0;
}
.card-expiry-date {
  margin: 0 !important;
}
.card-expiry-date input {
  padding: 0;
  font-size: 16px !important;
}
.card-expiry-date button.MuiButtonBase-root {
  padding: 0;
}

header .notification-link {
  position: absolute;
  right: 40px;
  width: 40px;
  height: 40px;
  padding: 8px 13px;
  border-radius: 50%;
  color: #fff;
}

.mob-nav.hide {
  display: none;
  height: 0;
}
.mob-nav.show {
  display: block;
  height: 100%;
  transition: all 0.3s ease;
}
.responsive-menu-btn {
  display: none;
}
.form-select {
  padding-left: 10px !important;
}
/* Absolute Center Spinner */
.loader-active {
  display: block;
}
.loader-inactive {
  display: none;
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* media */
@media (min-width: 768px) and (max-width: 991px) {
  header .nav li {
    padding: 0 10px;
  }
  header .nav a {
    font-weight: 500;
    font-size: 14px;
  }
  .pac-target-input {
    width: 220px !important;
  }
  .from-to label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .input-group > div:nth-child(2) {
    width: 80%;
  }
  .extrawrapper.hav-height { 
    height: 535px;
}
.fix-btn-bg.confirm-btn {
position: static;
}
.add-card-link {
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
}
.from-to input[type=radio]+label span{
  display: block;
}
}

@media (max-width: 1440px) {
  .fix-btn-bg.confirm-btn {
    bottom: calc(100% - 145px) !important;
}
 
.show-card-num{
  height: 20px !important;
}
}

@media (max-width: 767px) {
  .journey-info-content h4,  .journey-info-content div h4 {
    font-weight: 600 !important;
    font-size: 16px !important;
}
.journey-info-content div strong {
  font-size: 16px;
}
.journey-info-content div p {
  font-size: 14px !important;
}
  .add-card-link{
    position: static !important;
  }
  .input-group > div:nth-child(2) {
    width: 80%;
  }
  .journey-info {
    margin-bottom: 20px;
    min-height: 33vh;
  }
  body {
    overflow: scroll;
  }
  .web-nav {
    display: none !important;
  }
  header .notification-link {
    right: 60px;
    top: 15px;
  }
  .responsive-menu-btn {
    display: block;
    position: absolute;
    right: 20px;
    color: #229556;
    font-size: 24px;
  }
  .MuiGrid-root {
    width: 100% !important;
  }
  .MuiFormControl-root {
    width: 100% !important;
    margin-bottom: 15px !important;
  }
  .mob-nav {
    position: absolute;
    z-index: 999999999999;
    background: #e7e7e7;
    width: 100%;
    left: 2px;
    top: 61px;
    height: 430px !important;
    padding: 30px 21px;
    transition: height 0.3s ease;
  }
  .mob-nav li {
    padding: 15px 20px !important;
    text-align: center;
  }
  .container-fluid.dim-bg {
    height: auto !important;
  }
  .floating-sidebar {
    margin-bottom: 50px;
    min-height: 400px;
    height: auto;
  }
  .extrawrapper {
    padding-bottom: 70px;
  }
  .vehicle-select {
    max-height: 100%;
    height: 450px;
  }
  .fix-btn-bg.confirm-btn {
    bottom: auto;
    position: static;
  }
  .pac-target-input {
    width: 500px !important;
  }
  .page-wrapper > div {
    overflow-y: scroll !important;
  }
  #time-picker_time-input input {
    text-align: right;
  }
  .main-quote-page .floating-sidebar {
    min-height: 280px;
  }
  .container-fluid.dim-bg { 
    padding: 20px !important;
  }
  .container-fluid.dim-bg > .row { 
    padding: 20px !important;
  }
  .show-card-num{
    height: 20px !important;
  }
}

@media (max-width: 640px) {
  .pac-target-input {
    width: 385px !important;
  }
 
}

@media (max-width: 480px) {
  .pac-target-input {
    width: 290px !important;
  }
  .from-to input[type=radio]+label span{
    display: block;
  }
  .from-to input[type=radio]+label{
    font-size: 14px; 
    line-height: 20px;
  }
}
 
@media (max-width: 380px) {
  .pac-target-input {
    width: 230px !important;
  }
  
  .vehicle-select {
    max-height: 100%;
    height: 510px;
}
.journey-info h1{
  display: block;
}
.journey-info {
  padding: 10px;
}
.container-fluid.dim-bg > .row {
  padding: 10px !important;
}

}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 200px !important;
}
.react-datepicker__time-container {
  width: 200px !important;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.react-datepicker__time-list-item {
  font-size: 14px;
  font-weight: 600;
}

.p-25 {
  padding: 25px;
}
.p-125 {
  padding: 125px;
}

.container-fluid.dim-bg {
  background: #f1f1f1;
  padding: 60px;
  min-height: 100vh;
}
.container-fluid.dim-bg > .row {
  background: #fff !important;
  padding: 40px;

  box-shadow: 0px 0px 9px 3px rgba(171, 171, 171, 0.41);
}
.btn-black {
  background: #000;
  color: rgb(255 255 255);
  padding: 20px 0 !important;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}
.capacity.modal-body {
  padding-top: 15px !important;
}
.select-col-3 .select-align-div div:nth-child(2) {
  width: 75% !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.back-btn {
  color: #8f8f8f !important;
  margin-bottom: 10px;
}
.start-form-wrapper .input-group input {
  font-weight: 600;
}
.start-form-wrapper .input-group {
  margin-bottom: 15px;
}
.payment-page input[type="text"] {
  height: 50px;
  border-radius: 0;
}
.add-card-link {
  position: static; 
  background: #3c3c3c;
  cursor: pointer;
  text-align: center;
  padding: 16px 0 !important;
  display: block;
  color: #fff;
}
.card-info-modal .fa {
  color: rgb(114, 114, 114);
}
.card-info-modal .input-group-text,
.card-info-modal input {
  border-radius: 0;
}
.card-info-modal .MuiInputBase-root {
  border: 2px solid #6a6a6a;
  border-radius: 0 !important;
}
.card-info-modal .input-group {
  border: 2px solid #6a6a6a;
  border-radius: 0 !important;
}
.react-datepicker__header {
  background-color: #2b9d4e !important;
}
.react-datepicker-time__header {
  color: #fff !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}
.react-datepicker__time-list-item {
  font-size: 16px;
  color: #3e3e3e;
}

.date-time-wrapper i {
  position: relative;
  top: 38px;
  z-index: 9999;
  left: 18px;
}
.date-time-wrapper .react-datepicker-wrapper input {
  padding-left: 50px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-weight: 800;
}
.journey-info-content p {
  font-size: 15px !important;
  font-weight: 600;
}
.journey-info-content h4 {
  font-weight: 800;
  font-size: 18px !important;
}
.fix-btn-bg.confirm-btn {
  bottom: calc(100% - 150px);
}

.extrawrapper.hav-height {
  min-height: 90vh;
}
.thankyou-page{
  min-height: 91vh !important;
}

.thankyou-page h1{
  padding-top: 100px !important;
  font-size: 36px;
  padding-bottom: 20px !important;
  text-align: center;
}
.thankyou-page p{
  margin-bottom: 20px !important;
}
.thankyou-page .btn-main{
  display: inline-block;
  width: 270px;
}
.payment-form-wrapper .form-wrap{
  width: 60%;
  margin: 0 auto;
  margin-top: 10%;
  padding: 30px 20px;
  box-shadow: 0 0 8px 2px #e7e7e7;
  border-radius: 10px;
}
.payment-form-wrapper h1{
  text-align: center;
  margin-top:20vh !important;
  font-size: 24px; 
}
.payment-form-wrapper h1 i{
  display: block;
  font-size:105px;
  color: #17a2b8;
  margin-bottom: 25px;
}
.payment-form-wrapper .form-wrap h3{
  font-size: 24px;
  margin-bottom: 25px !important;
} 
  .payment-form-wrapper .form-wrap .form-group{
    margin-bottom: 15px;
  }



.vehicle-select::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.vehicle-select::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.vehicle-select::-webkit-scrollbar-thumb {
  background: #919191; 
}

/* Handle on hover */
.vehicle-select::-webkit-scrollbar-thumb:hover {
  background: #555; 
}